<template>
  <div class="widgetContainer cardList full-width">
    <div class="widgetContainer__header-fixed">
      <div class="headerExport">
        <p class="header-1">
          {{ $t('card_title') }}
        </p>
        <div v-if="false">
          <span class="icon-icon-export" />
          <p>
            {{ $t('export') }}
          </p>
          <span class="icon-arrow-right" />
        </div>
      </div>
      
      <div :class="'cardList__search ' + (cards.length !== 0 ? 'search-add': '')">
        <div class="searchInput">
          <el-input
            type="text"
            v-model="search"
            :placeholder="$t('card_search_placeholder')" clearable />
          <span class="icon-icon-search" />
        </div>
        <div class="add" @click="newCard" v-if="cards.length !== 0">
          <p> <span class="icon-plus" />  {{ $t('cotacts_list_addContact') }}</p>
        </div>
      </div>
    </div>
    
    <SkeletonCreditCard class="cardList__skeleton" :count="3" v-if="loading && page === 1" />

    <div class="widgetContainer__body widgetContainer--scrollable" v-else>
      <div class="cardList__list" v-infinite-scroll="load"
           :infinite-scroll-disabled="disabledLoadMore"
           :infinite-scroll-immediate="false">
        <ul class="cardList__dashboard-list">
          <li class="new-card" @click="newCard" v-if="cards.length === 0">
            <div class="new-card__inside">
              <div>
                <i class="el-icon-circle-plus-outline" />
                <h5>{{ $t('card_add_label') }}</h5>
              </div>
            </div>
          </li>
          <li v-for="card in cards" :key="card.id">
            <Card
              :show-logo="false"
              :show-activation-switch="true"
              :card="card"
              :show-mini-details="true"
              class="card-detail" 
              @changeDrawer="updateDrawer($event)" />
          </li>
        </ul>
      </div>
      <p v-if="loading && page !== 1">
        Loading...
      </p>
    </div>
    <Drawer class="default-drawer" :components="cardCreateRoutes" :show.sync="cardCreateDialog" />
    <Drawer class="default-drawer" :components="cardActivateRoutes" :show.sync="cardActviateDialog" />
    <Drawer class="default-drawer" :components="cardManageRoutes" :show.sync="cardMangeDialog" />
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import CardMixin from '../mixins/card';
import Card from '../components/Card';
import { cardStatus } from '../enums';
import Drawer from '@/components/Drawer.vue';
import SkeletonCreditCard from '@/components/Skeleton/SkeletonCreditCard.vue';

export default {
  name: 'CardList',
  components: {
    Card,
    Drawer,
    SkeletonCreditCard
  },
  mixins: [CardMixin],
  data() {
    return {
      search: '',
      cardActviateDialog: false,
      cardCreateDialog: false,
      cardMangeDialog: false,
      loading: true,
      page: 1,
      limit: 25,
      cardActivateRoutes: [
        {
          default: true,
          name: 'ActivateCardForm',
          component: () => import('@m/card/views/ActivateCardForm.vue')
        },
        {
          name: 'CardActivated',
          component: () => import('@m/card/views/CardActivated.vue'),
          methods: {
            refresh : () => {
              this.page = 1;
              this.getData();
            }
          },
        },
        {
          name: 'CardSpendingForm',
          component: () => import('@m/card/views/CardSpendingForm.vue')
        },
        {
          name: 'ReplaceCardReason',
          component: () => import('@m/card/views/ReplaceCardReason.vue')
        },
        {
          name: 'ReplaceCardDetails',
          component: () => import('@m/card/views/ReplaceCardDetails.vue')
        },
        {
          name: 'ReplaceCardAddress',
          component: () => import('@m/card/views/ReplaceCardAddress.vue')
        }
      ],
      cardCreateRoutes: [
        {
          default: true,
          name: 'CardTypeForm',
          component: () => import('@m/card/views/CardTypeForm.vue')
        },
        {
          name: 'CardSpendingForm',
          component: () => import('@m/card/views/CardSpendingForm.vue')
        },
        {
          name: 'CardBillingAddressForm',
          component: () => import('@m/card/views/CardBillingAddressForm.vue')
        },
        {
          name: 'CardShippingAddressForm',
          component: () => import('@m/card/views/CardShippingAddressForm.vue')
        },
        {
          name: 'CreatedSuccess',
          methods: {
            afterCreated : () => {
              this.page = 1;
              this.getData();
            },
            goToDetailsScreen: ($event) => {
              this.cardCreateDialog = false;
              this.updateDrawer($event);
            }
          },
          component: () => import('@m/card/views/CreatedSuccess.vue')
        }
      ],
      cardManageRoutes: [
        {
          default: true,
          name: 'CardManagement',
          component: () => import('@m/card/views/CardManagement.vue'),
          methods: {
            refresh : () => {
              this.page = 1;
              this.getData();
            }
          },
        },
        {
          name: 'CardDetails',
          component: () => import('@m/card/views/CardDetails.vue')
        },
        {
          name: 'CardSpendingForm',
          component: () => import('@m/card/views/CardSpendingForm.vue'),
          methods: {
            refresh : () => {
              this.page = 1;
              this.getData();
            }
          },
        },
        {
          name: 'AtmInfo',
          component: () => import('@m/card/views/ATMInfo.vue'),
        },
        {
          name: 'SetATMPin',
          component: () => import('@m/card/views/SetATMPin.vue'),
        },
        {
          name: 'findATM',
          component: () => import('@m/card/views/FindATM.vue'),
        },    
        {
          name: 'ReplaceCardReason',
          component: () => import('@m/card/views/ReplaceCardReason.vue')
        },
        {
          name: 'ReplaceCardDetails',
          component: () => import('@m/card/views/ReplaceCardDetails.vue')
        },
        {
          name: 'ReplaceCardAddress',
          component: () => import('@m/card/views/ReplaceCardAddress.vue')
        }
      ]
    };
  },
  computed: {
    ...mapGetters('card', ['getCards']),
    cards() {
      /** @type {import('@m/card/store/state').cardState['cards']} */
      const cards = this.getCards.data;
      if (this.search === '') {
        return this.getCards.data;
      }

      return cards.filter((c) =>
        c.label.toLowerCase().includes(this.search.toLowerCase())
      );
    },
    disabledLoadMore() {
      return (
        this.loading === true ||
        this.getCards.total <= this.page * this.limit
      );
    }
  },
  created() {
    this.getData();
  },
  methods: {
    ...mapActions('card', ['listAllCardByAccount']),
    ...mapMutations('card', [
      'updateSelectedCard',
      'updateReplaceCardDate',
      'updateCreateCardData'
    ]),
    updateDrawer(drawerRoute) {
      if (drawerRoute == 'activate') {
        this.cardActviateDialog = true;
      } else if (drawerRoute == 'manage') {
        this.cardMangeDialog = true;
      }
    },
    goToHome() {
      this.$router.push('/dashboard/home');
    },
    newCard() {
      this.updateCreateCardData(null);
      this.updateReplaceCardDate(null);
      this.updateSelectedCard(null);
      //this.$router.push('/card/typeForm');
      this.cardCreateDialog = true;
    },
    status(c) {
      return cardStatus[c.cardStatus];
    },
    getData() {
      // const loader = this.showLoader();
      this.loading = true;

      this.listAllCardByAccount({
        accountId: this.getSelectedAccount.id,
        limit: this.limit,
        offset: (this.page - 1) * this.limit
      })
        .catch((e) => {
          console.log(e);
          this.apiErrorMessage(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    load() {
      if (
        this.loading === false &&
        this.getCards.total > this.page * this.limit
      ) {
        this.page++;
        this.getData();
      }
    },
  },
  watch: {
    /**
     * selected account listener
     */
    getSelectedAccount(newVal, oldVal) {
      if(newVal && oldVal && newVal.id !== oldVal.id) {
        this.page = 1;
        this.getData();
      }
    }
  }
};
</script>

<style lang="scss">
  .cardList {
    overflow: hidden;

    &__skeleton {
      padding-right: 40px;
    }

    .widgetContainer__body {
      overflow-x: hidden;
      padding: 40px 36px 0px 0px;

      @media (max-width: 576px) {
        padding-right: 16px;
      }
      
      .cardList__dashboard-list {
        li {
          @media (max-width: 768px) {
            margin: 0 auto 32px auto;
          }
        }
      }
    }

    .cardList__skeleton {
      @media (max-width: 768px) {
        padding-right: 16px;
        
        .el-skeleton {
          flex-direction: column;
  
          .skeleton-credit-card__wrapper {
            margin: 0 0 16px 0;
          }
        }
      }
    }
    .search-add {
      display: flex;
    }
    .add {
        background-color: #fff;
        border-radius: var(--radius);
        border: 1px solid #eeeeee;
        cursor: pointer;
        margin-left: 10px;
        height: 64px;
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 14px 0 14px;
        transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

        &:hover {
          border-color: #C0C4CC;
        }

        @media screen and (max-width: 992px) {
          height: 48px;
        }

        span {
          padding-right: 4px;
          font-size: 14px;
        }

        p {
          font-size: 16px;
        }
      }
  }
</style>