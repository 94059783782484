import $t from '@/setup/i18n';

export const cardLimit = {
  daily: $t.t('limitPerDay'),
  weekly: $t.t('limitPerWeek'),
  monthly: $t.t('limitPerMonth'),
  yearly: $t.t('limitPerYear'),
  allTime: $t.t('allTime'),
  perTransaction: $t.t('limitPerTransaction'),

  cardBoxTitle: {
    daily: $t.t('limitDay'),
    weekly: $t.t('limitWeek'),
    monthly: $t.t('limitMonth'),
    yearly: $t.t('limitYear'),
    allTime: $t.t('limitAllTime'),
    perTransaction: $t.t('limitTransaction')
  }
};

export const cardStatus = {
  active: 'Active',
  inactive: 'Frozen',
  pendingActivation: 'Inactive'
};

export const cardType = {
  physical: $t.t('cardType_physical'),
  virtual: $t.t('cardType_virtual')
};
